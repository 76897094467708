import React, {useState} from 'react';
import { AppBar, Tabs, Tab, Typography, Box }  from '@mui/material';
import App from "./App";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const NavigationMenu:  React.FC = () => {
    const [value, setValue] = useState<number>(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Box>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} centered>
                    <Tab label="Item One" />
                    <Tab label="Item Two" />
                    <Tab label="Item Three" />
                    <Tab label="Item Four" />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                CBWS 1
            </TabPanel>
            <TabPanel value={value} index={1}>
                CBWS 2
            </TabPanel>
            <TabPanel value={value} index={2}>
                CBWS 3
            </TabPanel>
            <TabPanel value={value} index={3}>
                CBWS 4
                <App />
                <App />
            </TabPanel>
        </Box>
    );
};

export default NavigationMenu;
