import React from 'react';
import './App.css';
import Button from '@mui/material/Button';


function App() {
    return (
        <div className="App">
            <Button variant="contained">Hello world</Button>
        </div>
    );
}

export default App;
